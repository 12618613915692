import { STATUS } from '@/common/api/constants';
import { HandleErrorMessage, ResponseHandler } from '@/common/api/types';
import { clearLocalStore } from '@/common/utils/localStore';

export const getErrorMessage = async (response: Response): Promise<string> => {
  const parsedResponse = await response.json();
  if (
    typeof parsedResponse.error === 'string' ||
    typeof parsedResponse.message === 'string'
  ) {
    return parsedResponse.error || parsedResponse.message;
  } else {
    return 'Что-то пошло не так';
  }
};

export const handleErrorMessage: HandleErrorMessage = async ({
  response,
  setErrorMessage,
}) => {
  if (response) {
    const message = await getErrorMessage(response);
    setErrorMessage?.(message);
  }
};

export const responseHandler: ResponseHandler = async ({
  response,
  setErrorMessage,
  customHandler,
}) => {
  if (response.status === STATUS.UNAUTHORIZED) {
    clearLocalStore();
    window.location.replace('/login');
    return;
  }
  if (response.status === STATUS.UNPROCESSABLE_ENTITY) {
    await customHandler?.({ response, setErrorMessage });
    return response;
  }
  if (response.status === STATUS.REJECTED) {
    clearLocalStore();
    window.location.replace('/rejected');
    return response;
  }
  if (response.status >= STATUS.CLIENT_ERRORS) {
    await handleErrorMessage({ response, setErrorMessage });
    return;
  }
  if (response.status >= STATUS.SERVER_ERRORS) {
    setErrorMessage('Что-то пошло не так');
    return response;
  }
  return response;
};
