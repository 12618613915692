import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface CoordinatesProps {
  startX: number;
  startY: number;
  startLength: number;
  stepPixels: number;
  sliderWidth: number;
}

type UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  defaultValue,
  chunksAmount,
  handler,
  THUMB_ID,
}: {
  sliderWrapperRef: MutableRefObject<HTMLDivElement | null>;
  sliderRef: MutableRefObject<HTMLDivElement | null>;
  maxValue: number;
  minValue: number;
  defaultValue: number;
  chunksAmount: number;
  handler: (value: number) => void;
  THUMB_ID: string;
}) => {
  onSliderClick: (event: any) => void;
  onMouseDown: (event: MouseEvent) => void;
  onTouchStart: (event: any) => void;
  chunkLength: number;
};

export const useSlider: UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  defaultValue,
  chunksAmount,
  handler,
  THUMB_ID,
}) => {
  const [chunkLength, setChunkLength] = useState<number>(0);

  const coordinates = useRef<CoordinatesProps>({
    startX: 0,
    startY: 0,
    startLength: 0,
    stepPixels: 0,
    sliderWidth: 0,
  }).current;

  useEffect(() => {
    if (sliderWrapperRef.current) {
      const sliderWidth = sliderWrapperRef.current.clientWidth;
      coordinates.sliderWidth = sliderWidth;

      const chunkLength = sliderWidth / chunksAmount;
      setChunkLength(chunkLength);

      const chunksCount = defaultValue - minValue;

      const stepPixels = chunkLength * chunksCount;
      coordinates.stepPixels = stepPixels;

      (sliderRef.current as HTMLDivElement).style.width = `${stepPixels}px`;
    }
  }, [
    minValue,
    maxValue,
    defaultValue,
    chunksAmount,
    coordinates,
    sliderWrapperRef,
    sliderRef,
  ]);

  const onSliderClick = (event: any) => {
    if (!event.target.closest(`#${THUMB_ID}`)) {
      const { x: sliderStartX } = event.target.getBoundingClientRect();
      const touchX = event.clientX;
      const length = touchX - sliderStartX;
      const stopNominalValue = Math.round(length / chunkLength);
      const stopLength = stopNominalValue * chunkLength;
      const value = stopNominalValue + minValue;

      if (value >= minValue) {
        (sliderRef.current as HTMLDivElement).style.width = `${stopLength}px`;
        handler(value);
      }
    }
  };

  const onMove = useCallback(
    (clientX: number) => {
      const currentWidth =
        clientX - coordinates.startX + coordinates.startLength;

      const stopStep = Math.round(currentWidth / chunkLength);
      const value = stopStep + minValue;

      if (currentWidth >= 0 && currentWidth <= coordinates.sliderWidth) {
        handler(value);
      }

      if (currentWidth <= coordinates.sliderWidth) {
        (sliderRef.current as HTMLDivElement).style.width = `${currentWidth}px`;
      }
    },
    [coordinates, sliderRef, chunkLength, minValue, handler]
  );

  const onMouseMove = useCallback(
    (event: any) => {
      onMove(event.clientX);
    },
    [onMove]
  );

  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      onMove(event.touches[0].clientX);
    },
    [onMove]
  );

  const stopMove = useCallback(() => {
    const currentWidth = parseFloat(
      (sliderRef.current as HTMLDivElement).style.width
    );
    const stopStep = Math.round(currentWidth / chunkLength);
    const stopLength = stopStep * chunkLength;
    const value = stopStep + minValue;

    (sliderRef.current as HTMLDivElement).style.width = `${stopLength}px`;
    handler(value);
  }, [chunkLength, sliderRef, minValue, handler]);

  const onMouseUp = useCallback(() => {
    stopMove();
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  }, [stopMove, onMouseMove]);

  const onTouchEnd = useCallback(() => {
    stopMove();
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', onTouchEnd);
  }, [stopMove, onTouchMove]);

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      coordinates.startX = event.clientX;
      coordinates.startY = event.clientY;
      coordinates.startLength = parseFloat(
        (sliderRef.current as HTMLDivElement).style.width
      );
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [coordinates, onMouseMove, onMouseUp, sliderRef]
  );

  const onTouchStart = useCallback(
    (event: any) => {
      coordinates.startX = event.touches[0].clientX;
      coordinates.startY = event.touches[0].clientY;
      coordinates.startLength = parseFloat(
        (sliderRef.current as HTMLDivElement).style.width
      );
      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    },
    [coordinates, onTouchMove, onTouchEnd, sliderRef]
  );

  return {
    onSliderClick,
    onMouseDown,
    onTouchStart,
    chunkLength,
  };
};
