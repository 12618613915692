import { FormattedMessage } from 'react-intl';
import Document2 from '@/common/documents/dogovor-o-predostavlenii-mikrokredita.pdf';
import Document4 from '@/common/documents/licenziya.pdf';
import Document5 from '@/common/documents/politika-konfidecialnosti-i-obrabotka-personalnyh-dannyh.pdf';
import Document6 from '@/common/documents/pravila-predostavleniya-mikrokreditov-ehlektronnym-sposobom.pdf';
import Document7 from '@/common/documents/pravila-uregulirovaniya-prosrochennoj-zadolzhennosti.pdf';
import Document8 from '@/common/documents/procedura-bezopasnosti-i-zashchity-informacii.pdf';
import Document1 from '@/common/documents/soglasie-na-sbor-i-obrabotku-personalnyh-dannyh.pdf';
import Document10 from '@/common/documents/soglasie-subekta-kreditnoj-istorii-na-predstavlenie-svedenij.pdf';
import Document9 from '@/common/documents/zayavlenie-na-strahovanie.pdf';
import Document3 from '@/common/documents/zayavlenie-oferta-o-refinansirovanii-mikrokredita.pdf';

export const documentsList = [
  {
    title: (
      <FormattedMessage defaultMessage="Договор о предоставлении микрокредита" />
    ),
    link: Document2,
  },
  {
    title: <FormattedMessage defaultMessage="Заявление на страхование" />,
    link: Document9,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Заявление/Оферта о рефинансировании микрокредита" />
    ),
    link: Document3,
  },
  {
    title: <FormattedMessage defaultMessage="Лицензия" />,
    link: Document4,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Политика конфидециальности и обработка персональных данных" />
    ),
    link: Document5,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила предоставления микрокредитов электронным способом" />
    ),
    link: Document6,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Правила урегулирования просроченной задолженности" />
    ),
    link: Document7,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Процедура безопасности и защиты информации" />
    ),
    link: Document8,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Согласие на сбор и обработку персональных данных" />
    ),
    link: Document1,
  },
  {
    title: (
      <FormattedMessage defaultMessage="Согласие субъекта кредитной истории на представление сведений" />
    ),
    link: Document10,
  },
];
