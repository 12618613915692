import { FormattedMessage } from 'react-intl';

export const PAYMENT_AMOUNT_MIN = 5;
export const PAYMENT_AMOUNT_MAX = 999999;
export const LOAN_AMOUNT_MIN = 20000;
export const LOAN_AMOUNT_MAX = 165000;
export const LOAN_AMOUNT_INITIAL = 120000;
export const LOAN_DAYS_PERIOD_MIN = 10;
export const LOAN_DAYS_PERIOD_MAX = 30;
export const LOAN_DAYS_PERIOD_INITIAL = 15;
export const LOAN_DAY_RATE_MIN_PERCENT = 0.01;
export const LOAN_DAY_RATE_MAX_PERCENT = 0.3;
export const LOAN_ANNUAL_EFFECTIVE_RATE_PERCENT = 179;
export const ALLOWED_AGE_MIN = 21;
export const ALLOWED_AGE_MAX = 65;
export const PERIOD_LABEL = <FormattedMessage defaultMessage="дней" />;
export const MAX_CHILDREN_COUNT = 9;
export const MAX_STATEMENT_FILE_SIZE_MB = 30;
export const MAX_RELATIVE_PERSONS_COUNT = 5;
