import {
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

interface CoordinatesProps {
  startX: number;
  startY: number;
  startLength: number;
  stepPixels: number;
  sliderWidth: number;
}

type UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  initialValue,
  step,
  handler,
  THUMB_ID,
}: {
  sliderWrapperRef: MutableRefObject<HTMLDivElement | null>;
  sliderRef: MutableRefObject<HTMLDivElement | null>;
  maxValue: number;
  minValue: number;
  initialValue: number;
  step: number;
  handler: (value: number) => void;
  THUMB_ID: string;
}) => {
  onSliderClick: (event: any) => void;
  onMouseDown: (event: MouseEvent) => void;
  onTouchStart: (event: any) => void;
};

export const useSlider: UseSlider = ({
  sliderWrapperRef,
  sliderRef,
  maxValue,
  minValue,
  initialValue,
  step,
  handler,
  THUMB_ID,
}) => {
  const coordinates = useRef<CoordinatesProps>({
    startX: 0,
    startY: 0,
    startLength: 0,
    stepPixels: 0,
    sliderWidth: 0,
  }).current;

  useEffect(() => {
    if (sliderWrapperRef.current) {
      const sliderWidth = sliderWrapperRef.current?.clientWidth;
      coordinates.sliderWidth = sliderWidth;

      coordinates.stepPixels = (sliderWidth / (maxValue - minValue)) * step;

      (sliderRef.current as HTMLDivElement).style.width = `${0}px`;
    }
  }, [
    maxValue,
    minValue,
    initialValue,
    step,
    coordinates,
    sliderWrapperRef,
    sliderRef,
  ]);

  const onSliderClick = useCallback((event: any) => {
    if (!event.target.closest(`#${THUMB_ID}`)) {
      const { x: sliderStartX } = event.target.getBoundingClientRect();
      const touchX = event.clientX;
      const distanceRaw = touchX - sliderStartX;
      const stepsAmount = Math.ceil(distanceRaw / coordinates.stepPixels);
      const distance = stepsAmount * coordinates.stepPixels;
      const newValue = stepsAmount * step + minValue;

      if (newValue >= minValue) {
        (sliderRef.current as HTMLDivElement).style.width = `${distance}px`;
        handler(newValue);
      }

      if (newValue < minValue) {
        const distance = (minValue / step) * coordinates.stepPixels;
        (sliderRef.current as HTMLDivElement).style.width = `${distance}px`;
        handler(minValue);
      }
    }
  }, []);

  const onMouseMove = (event: any) => {
    onMove(event.clientX);
  };

  const onTouchMove = (event: TouchEvent) => {
    onMove(event.touches[0].clientX);
  };

  const onMove = (clientX: number) => {
    const initialWidth = coordinates.startLength;
    const initialStepsAmount = Math.ceil(initialWidth / coordinates.stepPixels);
    const stepsAmount = Math.ceil(
      (clientX - coordinates.startX) / coordinates.stepPixels
    );
    const newValue = (initialStepsAmount + stepsAmount) * step + minValue;

    let distance = (initialStepsAmount + stepsAmount) * coordinates.stepPixels;

    const trashEnd = coordinates.sliderWidth - coordinates.stepPixels;
    if (distance >= trashEnd && distance <= coordinates.sliderWidth) {
      distance = coordinates.sliderWidth;
    }

    if (
      distance >= 0 &&
      distance <= coordinates.sliderWidth &&
      newValue >= minValue
    ) {
      (sliderRef.current as HTMLDivElement).style.width = `${distance}px`;
      handler(newValue);
    }
  };

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onTouchEnd = () => {
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', onTouchEnd);
  };

  const onMouseDown = useCallback((event: MouseEvent) => {
    coordinates.startX = event.clientX;
    coordinates.startY = event.clientY;
    coordinates.startLength = parseFloat(
      (sliderRef.current as HTMLDivElement).style.width
    );
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, []);

  const onTouchStart = useCallback((event: any) => {
    coordinates.startX = event.touches[0].clientX;
    coordinates.startY = event.touches[0].clientY;
    coordinates.startLength = parseFloat(
      (sliderRef.current as HTMLDivElement).style.width
    );

    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
  }, []);

  return {
    onSliderClick,
    onMouseDown,
    onTouchStart,
  };
};
