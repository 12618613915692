import { FC } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface IconButtonProps {
  id: string;
  Icon: FC<{ color?: string }>;
  onClick: () => void;
  iconColor?: string;
  className?: string;
}

export const IconButton: FC<IconButtonProps> = ({
  id,
  Icon,
  onClick,
  iconColor,
  className,
}) => {
  return (
    <button
      id={`${id}-icon-button`}
      onClick={onClick}
      className={cn(styles.iconButton, className)}
    >
      <Icon color={iconColor} />
    </button>
  );
};
